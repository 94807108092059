import * as React from "react";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Welcome from "../views/Welcome";
import Home from "../views/Home";
import GuestBook from "../views/GuestBook";
import Bill from "../views/Bill";
import BillNoItem from "../components/modals/BillnoItem";
import Order from "../views/Order";
import Service from "../views/Service";
import SearchBar from "../components/home/SearchBar";
import SearchBarIndex from "../components/welcome/SearchBarIndex";
import NavigationDashboard from "../components/home/NavigationDashboard";
import PaymentFail from "../components/payment-fail/PaymentFail";
import PaymentSuccess from "../components/payment-success/PaymentSuccess";
import Page404 from "../views/Page404";
import {
  getOrdersFromFirebae,
  listentoOrderChanges
  // loadOrdersBySession
} from "../store/actions/orderAction";
// import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../services/socket.services";
import PrintRecipt from "../views/PrintRecips";
import ModalInterface from "../common/ModalInterface";
import { firebase } from "../store/firebase";
import { checkIsTableChangeEnable } from "../store/actions/tableActions";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
const queryClient = new QueryClient()
function App() {

  console.log("updated code")
  const tableInfo = useSelector((state) => state.table);
  const sessionId = useSelector((state) => state.table.sessionId);
  const page_theme = useSelector((state) => state.table?.business_Info?.body_bgcolor);

  const dispatch = useDispatch();

  useEffect(() => {
    const scsCount = sessionStorage.getItem("scsCount");
    if (scsCount == null) {
      sessionStorage.setItem("scsCount", String(scsCount + 1));
      dispatch(listentoOrderChanges(tableInfo?.sessionId));
    }
    return function cleanup() {
      sessionStorage.removeItem("scsCount");
    };
  }, [tableInfo]);

  const [isWelcome, setIsWelcome] = React.useState(window.location.pathname);
  const [print, setPrint] = React.useState(window.location.pathname);
  // const [isLoaded] = React.useState(true);
  const isLoaded = true;
  const [readyToPicMsg, setReadyToPicMsg] = React.useState(false);
  const searchSectionRef = React.useRef();
  const handalReadytopicMsg = () => {
    setReadyToPicMsg(true);
  };
  const showMeBar = () => {
    if (isWelcome !== "/" && isLoaded && print !== "/print-receipt") {
      return <SearchBar searchSectionRef={searchSectionRef} />;
      // } else if (!isLoaded) {
      //   return;
      // } else if (print === "/print-receipt") {
      // return;
    } else {
      return <SearchBarIndex />;
    }
  };
  useEffect(() => {
    firebaseOrderListner();
  }, []);
  const firebaseOrderListner = async () => {
    if (tableInfo?.sessionId) {
      await firebase
        .firestore()
        .collection("Local_Order")
        .where("sessionId", "==", tableInfo?.sessionId)
        .onSnapshot((snapshot) => {
          let updatedData = snapshot.docs.map((doc) => doc.data());
          if (updatedData?.length) {
            dispatch(getOrdersFromFirebae(updatedData[0]));
          }
        });
    }
  };

  useEffect(() => {
    socket.on("cookedRecipiesSessionID", async (data) => {
      if (tableInfo.sessionId === data) {
        handalReadytopicMsg();
      }
    });
    socket.on("order_received", (data) => {
      if (sessionId && tableInfo?.business_Info.blId && sessionId === data.sessionId) {
        // dispatch(
        //   loadOrdersBySession({
        //     businessLocationId: tableInfo?.business_Info.blId,
        //     sessionId: tableInfo.sessionId
        //   })
        // );
      }
      if (data?.deleted && data?.id === tableInfo.sessionId) {
        alert("Your session has been deleted!");
        window.location.replace(
          `/?buid=${tableInfo?.business_Info.blId}&table_no=${tableInfo?.orderInfo.tableNumber}`
        );
      }
    });
  }, [socket, sessionId]);
  useEffect(() => {
    dispatch(checkIsTableChangeEnable());
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
      <div className="App" style={{ backgroundColor: page_theme }}>
        {showMeBar()}
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route
            path="/home"
            element={<Home setIsWelcome={setIsWelcome} searchSectionRef={searchSectionRef} />}
          />
          <Route path="/service" element={<Service />} />
          <Route path="/guestBook" element={<GuestBook />} />
          <Route path="/order" element={<Order />} />
          <Route path="/bill" element={<Bill />} />
          <Route path="/billnoItem" element={<BillNoItem />} />
          <Route path="/order" element={<Order />} />
          <Route path="/paymentsuccess" element={<PaymentSuccess />} />
          <Route path="/paymentfail" element={<PaymentFail />} />
          <Route path="/print-receipt" element={<PrintRecipt setPrint={setPrint} />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        {print === "/print-receipt" && <></>}
        {isWelcome !== "/" && print !== "/print-receipt" ? <NavigationDashboard /> : <></>}
      </div>
      <ModalInterface
        display={readyToPicMsg}
        size="sm"
        center="true"
        handleClose={async () => {
          setReadyToPicMsg(false);
        }}
        title={
          <>
            <div className="d-flex justify-content-end p-2">
              <span
                className="bg-danger rounded-circle p-2 d-flex align-items-center justify-content-center text-white"
                style={{ height: "25px", width: "25px" }}>
                <i
                  className="fa fa-close fs-4"
                  onClick={async () => {
                    setReadyToPicMsg(false);
                  }}></i>
              </span>
            </div>
          </>
        }
        content={
          <>
            <h4>Your order is ready for pickup!</h4>
          </>
        }
      />
      </QueryClientProvider>
    </>
  );
}

export default App;
