import React, { useEffect } from "react";
import "../../assets/css/paymentsuccess.css";
import "../../assets/css/paymentfail.css";
import {useLocation, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPayAllOrdersBySession,
  SetLoading
} from "../../store/actions/orderAction";
import axios from "axios";

const PaymentFail = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const fullPayment = useSelector((state) => state.order.paymentStatus);

  const paymentInfo = useSelector((state) => state.order.orderPaymentInfo);
  const sessionId = useSelector((state) => state.table.sessionId);
  const location = useLocation();
  const payment_type = location?.state?.payment_type;
  const orderLoading = useSelector((state) => state.order.loading);
  const buid = useSelector((state) => state.table.orderInfo.businessLocationId);
  const orderId = useSelector((state) => state?.order?.ordersPlaced[0]?._id);

  useEffect(() => {
    dispatch(SetLoading(true));
    if ((paymentInfo !== null || true) && payment_type !== "pay_waiter") {
      axios
          .put(process.env.REACT_APP_BackendURL + `/payment-info/update-status/${orderId}`, {
            status: "Canceled", // replace with the desired status
          })
          .then((response) => {
            console.log("Payment status updated:", response.data);
          })
          .catch((error) => {
            console.error("Error updating payment status:", error);
          });
    }
  }, []);

  useEffect(() => {
    // dispatch(SetLoading(true));
    if (fullPayment) {
      dispatch(
          loadPayAllOrdersBySession({
            businessLocationId: buid,
            sessionId: sessionId
          })
      );
    }
  }, [fullPayment]);
  useEffect(() => {
    if (orderLoading) {
      dispatch(SetLoading(false));
    }
    setTimeout(() => {
      navigate("/bill");
    }, 3000);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('paymentStarted', false)
  }, [])

  return (
      <>
        <div className="bgcolor-white paymentFail pt-0 d-flex gap-5 flex-column align-items-center justify-content-center">
          <h1> Your Payment Fail</h1>
          <h5>
            Redirecting{" "}
            <span
                className="spinner-border spinner-border-lg text-danger text-center"
                role="status"
                aria-hidden="true"
            ></span>
          </h5>
          <button
              onClick={() => {
                navigate("/bill");
              }}
              className="btn btn-info text-light"
          >
            BESTELLUNG
          </button>
        </div>
      </>
  );
};

export default PaymentFail;